@media (max-width: 990px) {
  .mmgnnftr {
    margin-bottom: 25px !important;
  }
}

.new_footer_top {
  padding: 50px 0px 10px;
}

.nw-bckgnd {
  // background-image: url("/img/4858794.jpg");
  background-image: url("/img/4858794.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
}

.new_footer_area {
  background: #fbfbfd;
  margin-top: 20px;
}

// .new_footer_top {
//   padding: 50px 0px 230px;
//   position: relative;
//   overflow-x: hidden;
// }

.new_footer_top .f-title {
  margin-bottom: 20px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  // background: url("http://droitthemes.com/html/saasland/img/seo/footer_bg.png")
  //   no-repeat scroll center 0;
  height: 266px;
}

$timing: 200ms;

@mixin transformScale($size: 1) {
  transform: scale($size);
  -ms-transform: scale($size);
  -webkit-transform: scale($size);
}

.social-container {
  text-align: center;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em 0;
  text-align: left;

  li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.1em;
  }

  svg {
    color: #fff;
    position: absolute;
    top: 11px;
    left: 13px;
    transition: all $timing ease-out;
  }

  a {
    display: inline-block;

    &:before {
      @include transformScale();
      content: " ";
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: block;
      //background: linear-gradient(45deg, $iconColor, $accent);
      background-image: linear-gradient(
        109.6deg,
        rgba(31, 179, 237, 1) 11.2%,
        rgba(17, 106, 197, 1) 91.1%
      );
      transition: all $timing ease-out;
    }

    &:hover:before {
      transform: scale(0);
      transition: all $timing ease-in;
    }

    &:hover svg {
      @include transformScale(1.8);
      color: rgba(17, 106, 197, 1);
      //background: -webkit-linear-gradient(45deg, $iconColor, $accent);
      background-image: linear-gradient(
        109.6deg,
        rgba(31, 179, 237, 1) 11.2%,
        rgba(17, 106, 197, 1) 91.1%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all $timing ease-in;
    }
  }
}

.cprgt-bar {
  margin: 40px 0 10px;
  p {
    margin-bottom: 0px;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(32, 67, 82);
  }
}

.ftr-ancr {
  font-size: 14px;
  color: #4b505e;
}
